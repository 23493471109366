<template>
  <div class="carousel">
    <div class="slideshow-container">
      <div class="slide-number">{{ slideIndex + 1 }} / {{ items.length }}</div>
      <carousel-slide
        v-for="(item, index) in items"
        :item="item"
        :key="item.key"
        :active="slideIndex === index"
        ref="slide"
      />
      <a class="prev" @click="plusSlides(-1)">❮</a>
      <a class="next" @click="plusSlides(1)">❯</a>
    </div>
    <div style="text-align: center">
      <span
        v-for="(item, index) in items"
        :class="{ dot: true, active: slideIndex === index }"
        :key="item.key"
        @click="currentSlide(index)"
      ></span>
    </div>
  </div>
</template>
<script>
import CarouselSlide from "@/components/carousel/CarouselSlide.vue";

export default {
  components: { CarouselSlide },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slideIndex: 0,
    };
  },
  methods: {
    plusSlides(n) {
      if (n === -1 && this.slideIndex === 0) {
        this.slideIndex = this.items.length - 1;
        return;
      }
      this.slideIndex = (this.slideIndex + n) % this.items.length;
    },
    currentSlide(n) {
      this.slideIndex = n;
    },
  },
};
</script>
<style>
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    &:hover {
      color: var(--primary-color);
    }
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  .slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  &.active,
  &:hover {
    background-color: #717171;
  }
}

@media only screen and (max-width: 300px) {
  .prev,
  .next {
    font-size: 11px;
  }
}
</style>
