import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/faam",
    name: "Wij zijn faam",
    component: () => import("@/views/About/Main.vue"),
  },
  {
    path: "/faam/team",
    name: "team",
    component: () => import("@/views/About/TeamView.vue"),
  },
  {
    path: "/faam/praktijk",
    name: "praktijk",
    component: () => import("@/views/About/PraktijkView.vue"),
  },
  {
    path: "/faam/samenwerkingen",
    name: "samenwerkingen",
    component: () => import("@/views/About/PartnershipView.vue"),
  },
  {
    path: "/faam/vacatures",
    name: "vacatures",
    component: () => import("@/views/About/Vacatures.vue"),
  },
  {
    path: "/patient-stories",
    name: "patient-stories",
    component: () => import("@/views/PatientStoriesView.vue"),
  },
  {
    path: "/patient-verhalen",
    name: "patient-verhalen",
    component: () => import("@/views/PatientStoriesView.vue"),
  },
  {
    path: "/behandelvormen",
    name: "behandelvormen",
    component: () => import("@/views/Treatments/Main.vue"),
  },
  {
    path: "/treatments",
    name: "treatments",
    component: () => import("@/views/Treatments/Main.vue"),
  },
  {
    path: "/behandelvormen/fysiotherapie",
    name: "fysiotherapie",
    component: () => import("@/views/Treatments/Fysiotherapie.vue"),
  },
  {
    path: "/behandelvormen/manuele-therapie",
    name: "manuele-therapie",
    component: () => import("@/views/Treatments/ManualTherapy.vue"),
  },
  {
    path: "/behandelvormen/neurorevalidatie",
    name: "Neurorevalidatie",
    component: () => import("@/views/Treatments/Neurorevalidatie.vue"),
  },
  {
    path: "/behandelvormen/revalidatie-training",
    name: "revalidation",
    component: () => import("@/views/Treatments/Revalidation.vue"),
  },
  {
    path: "/behandelvormen/valpreventie",
    name: "Valpreventie",
    component: () => import("@/views/Treatments/Valpreventie.vue"),
  },
  {
    path: "/behandelvormen/massage",
    name: "massage",
    component: () => import("@/views/Treatments/Massage.vue"),
  },
  {
    path: "/behandelvormen/dry-needling",
    name: "dry-needling",
    component: () => import("@/views/Treatments/DryNeedling.vue"),
  },
  {
    path: "/behandelvormen/elektrische-spierstimulatie",
    name: "ElektrischeSpierstimulatie",
    component: () =>
      import("@/views/Treatments/ElektrischeSpierstimulatie.vue"),
  },
  {
    path: "/behandelvormen/medical-taping",
    name: "medicalTaping",
    component: () => import("@/views/Treatments/MedicalTaping.vue"),
  },
  {
    path: "/behandelvormen/virtual-reality-training",
    name: "virtual-reality-training",
    component: () => import("@/views/Treatments/VRTraining.vue"),
  },
  {
    path: "/behandelvormen/fitness",
    name: "Fitness",
    component: () => import("@/views/Treatments/Fitness.vue"),
  },
  {
    path: "/behandelvormen/e-consult",
    name: "e-consult",
    component: () => import("@/views/Treatments/EConsult.vue"),
  },
  {
    path: "/pijnklachten",
    name: "Pijnklachten",
    component: () => import("@/views/Complaints/Main.vue"),
  },
  {
    path: "/pijnklachten/nek",
    name: "Nekklachten",
    component: () => import("@/views/Complaints/Neck.vue"),
  },
  {
    path: "/pijnklachten/schouder",
    name: "Schouderklachten",
    component: () => import("@/views/Complaints/Shoulder.vue"),
  },
  {
    path: "/pijnklachten/elleboog",
    name: "Elleboogklachten",
    component: () => import("@/views/Complaints/Elbow.vue"),
  },
  {
    path: "/pijnklachten/pols",
    name: "Polsklachten",
    component: () => import("@/views/Complaints/Pols.vue"),
  },
  {
    path: "/pijnklachten/rug",
    name: "Rugklachten",
    component: () => import("@/views/Complaints/Back.vue"),
  },
  {
    path: "/pijnklachten/heup",
    name: "Heupklachten",
    component: () => import("@/views/Complaints/Hip.vue"),
  },
  {
    path: "/pijnklachten/knie",
    name: "Knieklachten",
    component: () => import("@/views/Complaints/Knee.vue"),
  },
  {
    path: "/pijnklachten/enkel",
    name: "Enkelklachten",
    component: () => import("@/views/Complaints/Ankle.vue"),
  },
  {
    path: "/pijnklachten/neurologie",
    name: "neurology",
    component: () => import("@/views/Complaints/Neurology.vue"),
  },
  {
    path: "/pijnklachten/overig",
    name: "Remaining",
    component: () => import("@/views/Complaints/Remaining.vue"),
  },
  {
    path: "/tarieven",
    name: "tarieven",
    component: () => import("@/views/TarievenView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/inloop-spreekuur",
    name: "inloop-spreekuur",
    component: () => import("@/views/InloopSpreekuurView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition) return savedPosition;

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      return {
        el: to.hash,
        top: 50,
      };
    }

    // scroll to top by default
    return { top: 0 };
  },
});

export default router;
