<template>
  <footer>
    <div class="container">
      <div class="column">
        <h1 class="masterplan">Behandelvormen</h1>
        <div class="treatment-list">
          <div v-for="treatment in treatments" :key="treatment.key">
            <p>
              <router-link :to="treatment.url">
                {{ treatment.title }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <h1 class="masterplan">Openingstijden</h1>
        <div class="open-times">
          <div class="open-time" v-for="time in openTimes" :key="time.key">
            <h4 class="day">{{ time.day }}</h4>
            <p
              v-for="location in time.locations"
              class="time"
              :key="location.key"
            >
              {{ location.start }} - {{ location.end }} ({{ location.name }})
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <h1 class="masterplan">Locaties</h1>
        <h4>Uden</h4>
        <p class="address-description">Klarinetstraat 4</p>
        <p class="address-description">5402BE Uden</p>
        <br />
        <h4>Wilbertoord</h4>
        <p class="address-description">Wethouder Lindersstraat 11</p>
        <p class="address-description">5455GJ Wilbertoord</p>
      </div>
      <div class="column">
        <div class="contact-info">
          <h1 class="masterplan">Contactgegevens</h1>
          <p class="mail">
            <strong>Email: </strong>
            <a href="mailto:info@faamfysiotherapie.nl"
              >info@faamfysiotherapie.nl</a
            >
          </p>
          <p class="phone">
            <strong>Telefoon: </strong>
            <a href="tel:0413783357">0413-783357</a>
          </p>
        </div>
        <div class="socials">
          <h1 class="masterplan">Socials</h1>
          <a
            v-for="social in socials"
            :href="social.url"
            target="_blank"
            :key="social.key"
          >
            <span :class="'mdi mdi-' + social.title.toLowerCase()"></span>
          </a>
        </div>
        <div class="vacatures">
          <h1 class="masterplan">Vacatures</h1>
          <a href="/faam/vacatures">Bekijk onze vacatures</a>
        </div>
        <!-- <trustoo /> -->
      </div>
    </div>
    <div class="bottom-container">
      <div>
        <p>
          <a @click="showPrivacyStatement = true">Privacy Voorwaarden</a>
        </p>
      </div>
      <div class="copyright">
        <p>© {{ year }} Copyright - Faam Fysiotherapie</p>
      </div>
    </div>
  </footer>
  <modal :show="showPrivacyStatement" @close="showPrivacyStatement = false">
    <iframe
      src="/assets/Privacy-Statement-Faam-Fysiotherapie.pdf"
      frameborder="0"
      style="height: 100%; width: 100%"
    ></iframe>
  </modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
// import Trustoo from "./widget/trustoo.vue";

export default {
  name: "Bottom",
  components: {
    Modal,
    // Trustoo,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showPrivacyStatement: false,
      treatments: [
        { title: "Fysiotherapie", url: "/behandelvormen/fysiotherapie" },
        { title: "Manuele Therapie", url: "/behandelvormen/manuele-therapie" },
        { title: "Neurorevalidatie", url: "/behandelvormen/neurorevalidatie" },
        {
          title: "Revalidatietraining",
          url: "/behandelvormen/revalidatie-training",
        },
        {
          title: "Valpreventie",
          url: "/behandelvormen/valpreventie",
        },
        { title: "Massage", url: "/behandelvormen/massage" },
        { title: "Dry Needling", url: "/behandelvormen/dry-needling" },
        {
          title: "Elektrische Stimulatie (Compex)",
          url: "/behandelvormen/elektrische-spierstimulatie",
        },
        { title: "Medical Taping", url: "/behandelvormen/medical-taping" },
        {
          title: "Virtual Reality Training",
          url: "/behandelvormen/virtual-reality-training",
        },
        { title: "Fitness", url: "/behandelvormen/fitness" },
        { title: "E-consult", url: "/behandelvormen/e-consult" },
      ],
      openTimes: [
        {
          day: "Maandag",
          locations: [
            { name: "Uden", start: "08:30", end: "21:00" },
            { name: "Wilbertoord", start: "09:00", end: "17:00" },
          ],
        },
        {
          day: "Dinsdag",
          locations: [{ name: "Uden", start: "08:30", end: "21:00" }],
        },
        {
          day: "Woensdag",
          locations: [{ name: "Uden", start: "08:30", end: "17:00" }],
        },
        {
          day: "Donderdag",
          locations: [
            { name: "Uden", start: "08:30", end: "21:00" },
            { name: "Wilbertoord", start: "09:00", end: "17:00" },
          ],
        },
        {
          day: "Vrijdag",
          locations: [{ name: "Uden", start: "08:30", end: "20:00" }],
        },
      ],
      socials: [
        {
          title: "Facebook",
          url: "https://www.facebook.com/FaamFysiotherapie",
        },
        {
          title: "Instagram",
          url: "https://www.instagram.com/faamfysiotherapie/",
        },
        {
          title: "Youtube",
          url: "https://www.youtube.com/channel/UCWXUFTFDRpIy-MdRlYuurLw",
        },
        {
          title: "Whatsapp",
          url: "https://wa.me/+31621436994",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
footer {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: #4e4d4d;
  .container {
    padding: 50px 200px;
    display: flex;
    flex-wrap: wrap;
    .column {
      width: calc(100% / 4);
      a {
        color: white;

        &:hover {
          color: var(--primary-color) !important;
        }
      }
    }
    .socials {
      a {
        font-size: 30px;
        margin: 0 10px 0 0;
      }
    }
  }
  .bottom-container {
    display: flex;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px;
    justify-content: space-around;
    align-content: space-around;
    a {
      color: var(--secondary-color);
      text-decoration: none;
      &:hover {
        color: white;
      }
    }
    .copyright {
      text-align: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  footer {
    .container {
      padding: 50px 100px;
      .column {
        width: calc(100% / 2);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    .container {
      padding: 30px 50px;
      flex-direction: column;
      .column {
        width: 100%;
      }
    }
    .bottom-container {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
